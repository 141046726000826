// CSS Variables for the project.

// Mettre les différentes variables tirées du fichier psd du designer

//********************
// FONTS
// >> Allez configurer les fonts dans _fonts.scss

// font size dans le document PSD:
$font-size-base-lg: 18px;
$font-size-base-md: 16px;
$font-size-base-sm: 14px;
$font-size-base-xs: 12px;
@import 'font-sizes'; // variables for font-sizes using rem units

/********************/
// COLORS
//
$color-black: #000;
$color-white: #fff; // needed
$color-grey: #58585a;  // needed
$color-red:  #d21033;
$color-grey-dark: #353535; // needed body
$color-grey-darker: #262523; // footer
$color-grey-light: #b5b5b5;
$color-1: rgb(0,147,216); // needed (red)
$color-primary: $color-1;
$color-2: $color-black; // needed (orange)
$color-secondary: $color-2;

$blue-bg : #0046b6;

$color-blue-0 : #0147b6;
$color-red-1 : #d21033;
$color-red-1-hover : #a0001d;
$color-blue : #004b8e;
$color-blue-2 : #032251;
$color-blue-2-hover : #000c1f;

// TEXT COLORS

$color-text-base: $color-1; // Mettre la couleur
$color-text-punch: $color-black; // Mettre la couleur
$color-text-contrast: $color-white; // Mettre la couleur

// $color-text-base-light: lighten($color-text-base, 20%);
// $color-text-punch-light: lighten($color-text-punch, 20%);
// $color-text-contrast-light: lighten($color-text-contrast, 20%);
// $color-text-base-dark: darken($color-text-base, 20%);
// $color-text-punch-dark: darken($color-text-punch, 20%);
// $color-text-contrast-dark: darken($color-text-contrast, 20%);

// BACKGROUNDS

$bg-color-body: $color-white; // needed
$bg-color-nav: $color-white; // needed
$bg-color-nav-logo: $color-white; // needed
$bg-color-nav-highlight: $color-white; // needed
$bg-color-black: $color-black; // needed
$bg-color-transparent: rgba(0,0,0,0.4);
$bg-color-footer: $color-grey-darker; // needed
$bg-color-primary: $color-1; // needed
$bg-color-secondary: $color-1; // needed


// ********************
// RESPONSIVE
//

$size-xs-max: 767px;
$size-sm-min: 768px;
$size-sm-max: 990px;
$size-md-min: 991px;
$size-md-max: 1299px;

$size-max-1: 1755px;
$size-max-2: 1535px;
$size-max-3: 1375px;
$size-max-4: 1199px;
$size-max-5: 1099px;

$size-max-m-1: 991px;
$size-max-m-2: 767px;
$size-max-m-3: 675px;
$size-max-m-4: 480px;

$size-nav-shift-breakpoint: $size-md-max; // change height
$size-nav-toggle-breakpoint: $size-sm-max; // show mobile menu
$size-slider-breakpoint: 480px;

// ********************
// SIZES
//

$size-footer-height: 100px; // needed
$size-footer-width-desktop: 50%; // needed
$size-footer-width-mobile: 100%; // needed
$size-nav-height: 104px; // needed
$size-nav-height-mobile: 75px; // needed
$size-nav-subitem: 25px; // needed
$size-nav-toggle-bar-height: 2px; // needed

$size-info-strip: 50px;  // theme

// ******************
// Z-INDEXES
//
$z-index-0: 0;
$z-index-animation-0: 10;
$z-index-animation-1: 15;
$z-index-animation-2: 20;

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'fonts'; // utility classes for fonts
@import 'spacing'; // utility classes for spacing
@import 'colors'; // utility classes for colors
@import 'flex'; // utility classes for flex box

@import 'hover';
@import 'responsive';
@import 'typography';
@import 'buttons';
@import 'layerslider';
@import 'animation';
