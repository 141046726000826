
/** to add hover effect on icon and logo **/
/*.icon,*/ .logo {
    a {
        position: relative;
        .overlay {
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top:0;
            background-image: linear-gradient(transparent, $color-primary);
            mix-blend-mode: overlay;
            transition: all 0.2s ease-in;
            //border-radius: 50%;
            // for IE explorer fallback
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                background: transparentize($color-black, 0.5);
            }
            // For Edge fallback
            @supports (-ms-ime-align:auto) {
                background: transparentize($color-black, 0.5);
            }
        }
        &:hover {
            .overlay {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}