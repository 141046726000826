$input_height: 50px;
$input_back_color: #fff;
$input_text_color: #000;
$input_text_size: 20px;

$input_placeholder_color: #CCC;
$input_placeholder_size: 18px;

$input_border_color: $color-red;
$input_border_radius: 0;
$input_border_size: 1px;
$input_border_style: solid;

$input_margin: 10px 0;
$text_color: #fff;
$label_color: #fff;
$label_checkbox_border_color: $label-color;
$input_checked_color: $color-primary;

$textarea_height: 200px;

$message_errors_text_color: white;
$message_errors_border_color: hsla(0, 100%, 55%, 1);
$message_errors_border_size: 5px;
$message_errors_border_radius: 5px;
$message_errors_background_color: hsla(0, 100%, 55%, 0.7);
$message_success_text_color: white;
$message_success_border_color: hsla(120, 100%, 50%, 1);
$message_success_border_size: 5px;
$message_success_border_radius: 5px;
$message_success_background_color: hsla(120, 100%, 50%, 0.5);

form {
    input.form-control, textarea.form-control {
        height: $input_height;
        color: $input_text_color;
        font-size: $input_text_size;
        background-color: $input_back_color;
        border: $input_border_size $input_border_style $input_border_color;
        margin: $input_margin;
        border-radius: 3px;
        border: 2px solid $color-blue;
    }
    input::placeholder, textarea::placeholder {
        font-size: $input_placeholder_size;
        color: $input_placeholder_color;
    }
    .title {
        text-transform: uppercase;
        color: $text_color;
        font-weight: bold;
        margin-top: 1em;
    }
    .center {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .radios, .checkboxes {
        width: 100%;
        margin: 20px 0 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input[type="radio"], input[type="checkbox"] {
            position: absolute;
            visibility: hidden;
            &:checked+label:before {
                background-color: $input_checked_color;
            }
        }
        input[type="radio"] {
            &+label:before {
                border-radius: 100%;
            }
        }
        label {
            display: inline-block;
            cursor: pointer;
            color: $label_color;
            position: relative;
            padding: 6px 5px 6px 35px;
            font-size: $input_text_size;
            transition: all .3s ease;
            &:before {
                content: "";
                display: inline-block;
                width: $input_text_size;
                height: $input_text_size;
                position: absolute;
                left: 0px;
                background: none;
                border: 2px solid $label_checkbox_border_color;
            }
        }
    }
    .radios {
        justify-content: space-between;
    }
    textarea {
        margin: 2em 0;
        height: $textarea_height !important;
    }
    .buttons {
        margin: $input_height 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        div.btn-submit {
            width: 350px;
            @media screen and (max-width: $size-max-4) {
                width: 300px;
            }
            label.button, button#submit {
                //border: $input_border_size $input_border_style $input_border_color;
                border-radius: $input_border_radius;
            }
            button#submit {
                background: $color-red-1;
                color: $color-white;
                margin-top: 15px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                span {
                    width: 100%;
                    height: 44px;
                    border: 1px solid $color-white;                    
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    transition: 5s;
                }
            }
            button#submit:hover {
                background: $color-red-1-hover;
                transition: 5s;
            }
        }
    }    
}

form input.form-control, form textarea.form-control {
    //border: 1px solid #CCC!important;
}

.messages {
    padding: 30px;
    margin-bottom: 2em;
    &.errors, &.success {
        p {
            font-size: 24px;
            line-height: 1;
            margin: 0
        }
    }
    &.errors {
        background-color: $message_errors_background_color;
        border-radius: $message_errors_border_radius;
        border: $message_errors_border_size solid $message_errors_border_color;
        color: $message_errors_text_color;
        p {
            color: $message_errors_text_color;
        }
    }
    &.success {
        background-color: $message_success_background_color;
        border-radius: $message_success_border_radius;
        border: $message_success_border_size solid $message_success_border_color;
        color: $message_success_text_color;
        p {
            color: $message_success_text_color;
        }
    }
}

div.help-block li {
    position: absolute;
    margin-top: -18px;
    font-size: 0.7rem!important;
}

div.section-form {
    padding: 65px 0;
    padding-bottom: 0;

    #fileDoc {
        display: none;
    }

    .fileDoc-input {
        width: 100%;
        padding: 15px;
        background: $color-blue;
        color: $color-white;
        border-radius: 2px;
        font-weight: 400;
        cursor: pointer;
        transition: .5s;
    }

    .fileDoc-input:hover {
        background: $color-blue;
        transition: .5s;
    }
}

section.type-evement {
    label {
        font-size: 16px;
        font-weight: 700;
        color: $color-black;
        margin-bottom: 20px;
    }

    label.radio-inline {
        font-weight: 400;
        font-size: 14px;
        color: $color-black;
    }

    div.custom-checkbox {
        label {
            font-weight: 400;
            font-size: 14px;
        }
    }

    div.form-group {
        /* The container */
        .container {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 20px;
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            color: $color-black;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            line-height: 20px;
            transition: .5s;

            span {
                margin-top: -4px;
                transition: .5s;
            }
        }

        /* Hide the browser's default checkbox */
        .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            transition: .5s;
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #eee;
            transition: .5s;
        }
        
        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
            background-color: #ccc;
            border: 2px solid $color-blue;
            border-radius: 3px;
            transition: .7s;
        }
        
        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
            background-color: $color-white;
            border: 1px solid $color-blue;
            transition: .5s;
        }
        
        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            //display: none;
            opacity: 0;
            transition: .5s;
        }
        
        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
            //display: block;
            opacity: 1;
            transition: .5s;
        }
        
        /* Style the checkmark/indicator */
        .container .checkmark:after {
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border: solid $color-white;
            background: $color-blue;
            //border-width: 8px 6px 5px 7px;
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
            transition: .5s;
        }
    }

    div.block-radios {
        /* The container */
        .container {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            color: $color-black;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            line-height: 20px;
        }
        
        /* Hide the browser's default radio button */
        .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
        
        /* Create a custom radio button */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #eee;
            border-radius: 50%;
        }
        
        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
            background-color: #ccc;
        }
        
        /* When the radio button is checked, add a blue background */
        .container input:checked ~ .checkmark {
            background-color: $color-blue;
        }
        
        /* Create the indicator (the dot/circle - hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        
        /* Show the indicator (dot/circle) when checked */
        .container input:checked ~ .checkmark:after {
            display: block;
        }
        
        /* Style the indicator (dot/circle) */
        .container .checkmark:after {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: $color-blue;
        }
    }

    div.special-exception {
        label {
            margin-bottom: 0;
        }
    }
}

div.message-content {
    label {
        font-size: 16px;
        font-weight: 700;
        color: $color-black;
        margin-bottom: 20px;
    }
}

.label-title {
    color: $color-blue;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;

}