.bursts {
    width: 100%;
    .bursts-container {
        @extend .flex;
        .burst {
            // flex: 1;
            position: relative;
            .text-container, .overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .text-container {
                padding: 25px;
                .border {
                    border: 1px solid $color-white;
                    width: 100%;
                    height: 100%;
                    @extend .flex;
                    @extend .flex-column;
                    @extend .justify-center;
                    @extend .items-center;
                }
            }
            .overlay {
                background-image: linear-gradient(to top, $color-secondary,$color-primary);
                // z-index: 1;
                mix-blend-mode: hue;
                opacity: 0;
                transition: all 0.2s ease;
                // for IE explorer fallback
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    background: transparentize($color-black, 0.5);
                }
                // For Edge fallback
                @supports (-ms-ime-align:auto) {
                    background: transparentize($color-black, 0.5);
                }
            }
            h3,h4 {
                color: $color-white;
            }
            h3 {
                @extend .font-2;
                font-size: 4vw;
                font-weight: 600;
                text-transform: none;
            }
            h4 {
                @extend .font-1;
                font-size: 2vw;
            }
        }
        a:hover .burst {
            .overlay {
                opacity: 0.5;
            }
            h3, h4 {
                color: $color-secondary;
            }
            .border {
                border-color: $color-secondary;
            }
        }
    }
}