// IMPORT ADDONS
@import 'addons/bootstrap/bootstrap';

/* purgecss start ignore */

// @import 'addons/font-awesome/font-awesome';
// @import 'addons/owl-carousel2/owl.carousel';
@import 'addons/layerslider/layerslider';
// @import 'addons/magnific-popup/magnific-popup';
// @import 'addons/cube-portfolio/cubeportfolio';
// @import 'addons/parallax-scroll/normalize';
// @import 'addons/parallax-scroll/main';
@import '_alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'reset';
@import 'variables';
@import 'functions';

/* GENERAL TEMPLATE */


a.btn-loi {
    width: 100px;
    position: fixed;
    left: 10px;
    z-index: 30;
    bottom: 15px;
    @media screen and (max-width: $size-xs-max) {
        width: 75px;
        left: initial;
        right: 10px;
    }
}

body {
    background-color: $bg-color-body;
    font-family: arial;
    h1.referencement {
        display: none !important;
    }
    .appear {
        opacity: 0;
        @include transition(opacity 0.75s ease-in);
    }
    .parallax, .parallax-slow, .parallax-fast {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }

    section.sectionWeb01 {
        //margin-top: -25px;
        font-family: Arial;
        .section-text-image {
            background-color: #FFF;
            .image {
                img {
                    // -webkit-box-shadow: 0px 10px 25px 0px rgba(0, 9, 23, 0.88);
                    // -moz-box-shadow: 0px 10px 25px 0px rgba(0, 9, 23, 0.88);
                    // box-shadow: 0px 10px 25px 0px rgba(0, 9, 23, 0.88);
                    width: 100%;
                }
            }
            .items-start {
                display: flex;
                justify-content: center;
                .paragraphs-text {
                    left: 0;
                    float: left;
                    z-index: 9;
                    position: absolute;
                    padding-top: 7vw;
                    padding-left: 8vw;
                    //width: 50%;
                    h3 {
                        color: $color-red;
                        font-size: 40px;
                        text-align: left;
                        margin-bottom: 30px;
                        font-weight: 500;
                        line-height: 50px;
                    }
                    p {
                        font-weight: 400;
                        color: #0147b7;
                        font-family: arial;
                        font-size: 1.30vw;
                        line-height: 3.0vw;
                    }
                    @media screen and (max-width: 991px) {
                        h3 {
                            text-align: center;
                            font-size: 28px;
                            line-height: 35px;
                            padding-top: 50px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1550px) {
        section.sectionWeb01 {
            .section-text-image {
                .items-start {
                    .paragraphs-text {
                        width: 50%;
                        padding-top: 6vw;
                        padding-left: 8vw;
                        p {
                            font-size: 1.8vw;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: $size-md-min) {
        section.sectionWeb01 {
            .section-text-image {
                .items-start {
                    display: block;
                    .paragraphs-text {
                        width: 100%;
                        padding-top: 6vw;
                        padding-left: 5vw;
                        position: relative;
                        p {
                            font-size: 22px;
                            line-height: 43px;
                            text-align: center;
                            padding-top: 35px;
                            padding-bottom: 35px;
                        }
                    }
                    .image-side-mobile {
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
        section.sectionWeb03 {
            .bursts {
                .bursts-container {
                    .box-left-content {
                        //Left side
                    }
                    .box-right-content {
                        // Mobile right side
                        a {
                            h1 {
                                font-size: 2.8vw!important;
                            }
                            h5 {
                                font-size: 14px!important;
                            }
                        }
                    }
                    @media screen and (max-width: 768px) {
                        .box-right-content {
                            // Mobile right side
                            a {
                                h1 {
                                    font-size: 20px!important;
                                }
                                h5 {
                                    font-size: 14px!important;
                                }
                            }
                        }
                    }
                }
            }
        }        
    }

    section.sectionWeb02 {
        background: url(../images/parallax_strip.jpg) no-repeat center center;
        background-size: cover;
        padding: 25px;
        padding-top: 50px;
        div.centrale-brust {
            div.container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                img {
                    max-width: 112px;
                    width: 112px;
                    margin: 0 auto;
                }
                p {
                    font-weight: 400;
                    color: #FFF;
                    font-family: arial;
                    font-size: 25px;
                    line-height: 40px;
                    text-align: center;
                    margin-top: 15px;
                }
            }
        }
    }

    section.section02 {
        background-color: #032251 !important;
        -webkit-box-shadow: 0px 10px 25px 0px rgba(50, 50, 50, 0.94);
        -moz-box-shadow: 0px 10px 25px 0px rgba(50, 50, 50, 0.94);
        box-shadow: 0px 10px 25px 0px rgba(50, 50, 50, 0.94);
        margin-bottom: 50px;
    }

    section.sectionWeb03 {
        margin-bottom: -84px;
        padding-top: 50px;
        div.bursts {
            padding: 35px;
            div.bursts-container {
                display: flex;
                max-width: 1615px;
                justify-content: center;
                border: 2px solid #aa9c9b;
                padding-left: 0;
                padding-right: 0;
                .col-md-5, .col-md-2 {
                    padding-left: 0;
                    padding-right: 0;
                    overflow: hidden;
                }
                .box-right-content {
                    //padding-left: 65px;
                    img {
                        float: right;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        height: 100%;
                    }
                    img.bkg-right {
                        position: absolute;
                        top: 0;
                        right: -2px;
                        z-index: -1;
                        width: 100%;
                        height: 100%;
                    }
                    a {
                        color: #FFF;
                        display: block;
                        height: 100%;
                        padding-top: 30px;
                        h5 {
                            padding-left: 30px;
                            font-size: 22px;
                        }
                        h1 {
                            padding-left: 30px;
                            font-size: 2.3vw;
                        }
                        hr {
                            border-top: 2px solid #fff;
                        }
                    }
                    div.div-bkg-right {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        background: #2b5492;
                        z-index: -1;
                    }
                }
                .box-center-content {
                    img {
                        text-align: right;
                        right: 0;
                        position: absolute;
                        top: 40%;
                    }
                    img.img-center-2 {
                        right: auto;
                        margin: 0 auto;
                        /* top: 0; */
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }
                }
            }
        }
        @media screen and (max-width: $size-xs-max) {
            .bursts-container {
                display: flex;
                flex-direction: column-reverse;
                .box-left-content {
                    width: 100%;
                    a {
                        width: 100%;
                        img {
                            width: 100%;
                        }
                    }
                }
                .box-right-content {
                    width: 100%;
                    a {
                        margin-bottom: 35px;
                    }
                    img.bkg-right {
                        right: 0px!important;
                    }
                }
            }
        }
    }

    section.section-icon-content {
        .row {
            .container {
                ul {
                    list-style: none;
                    list-style-type: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 75px;
                    margin-bottom: 75px;
                    li {
                        margin-right: 25px;
                    }
                }
            }
            .container-icons {
                display: flex;
                justify-content: center;
                //padding-top: 75px;
                padding-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .container-icons {
            display: block!important;
        }
    }
}

.section03-burst {
    padding: 50px 10%;
    padding-bottom: 0px;
	h3 {
		text-align: center;
		font-size: 40px;
	}

	.burst-box01, .burst-box02, .burst-box03, .burst-box04 {
		margin: 50px 0;
		cursor: pointer;
		@extend .flex;
		@extend .items-center ;
		@extend .justify-between;
		border: 2px solid #CCC;
        height: calc(100vw *  200 / 1880);
        overflow: hidden;
		.img-left {
			width: 41%;
		}
		.center {
			width: 20%;
			height: 100%;
			@extend .flex;
			@extend .items-center;
			position: relative;
			@include transition(all 0.5s ease);
			img {
				@include transition(all 0.5s ease);
				//margin: 0 auto;
				position: absolute;
				top: 50%;
				right: 50%;
				@include transform(translate(50%, -50%));
			}
		}
		.section-text {
			width: 40%;
			height: 100%;
			position: relative;
			@extend .flex;
			@extend .flex-column;
			@extend .justify-around;
			.interior {
				background-position: center;
				background-size: cover;
				position: absolute;
				height: 100%;
				width: 100%;
				left: 0;
				top: 0;
			}
			.overlay {
				position: absolute;
				height: 100%;
				width: 100%;
				left: 0;
				top: 0;
				background: rgba(51, 91, 160, 0.30980392156862746);
				@include transition(all 0.5s ease-in);

			}

			h4 {
                color: #FFF;
				font-size: 2.1vw;
                line-height: 25px;
                margin: 0 0 10px 0;
                margin-top: 12px;
                font-weight: 500;
                text-shadow: 0px -1px 2px rgb(27, 27, 27);
            }

            h5 {
                color: #FFF;
                font-size: 22px;
                text-shadow: 0px -1px 2px rgb(27, 27, 27);
            }
			p {
				color: #fff;
				font-size: 18px;
				padding-left: 10px;
                margin: 10px 0 0 0;
                text-shadow: 0px -1px 2px rgb(27, 27, 27);
			}

			.top-text {
				border-bottom: 1px solid #fff;
				z-index: 1;
				height: 50%;
				@extend .flex;
                //@extend .items-end;
                flex-direction: column;
                padding: 15px 25px;
			}
			.bottom-text {
				z-index: 1;
				height: 50%;
				@extend .flex;
                @extend .items-start;
                h5 {
                    left: 30px;
                    color: #FFF;
                    font-size: 22px;
                    text-shadow: 0px -1px 2px #1b1b1b;
                    margin-top: 15px;
                    position: relative;
                }
			}
		}
		&:hover {
			.section-text {
				.overlay {
                    //background: rgba(2, 50, 134, 0.603);
                    background: rgba(10, 53, 117, 0.808);;
					@include transition(all 0.5s ease);
				}
			}
			.center {
			//	@extend .justify-end;
				@include transition(all 0.5s ease);
				img {
					right: 0;
					@include transform(translate(0, -50%));
					@include transition(all 0.5s ease);
				}
			}
        }
        @media screen and (max-width: 1572px) {
            div.section-text {
                .top-text {
                    padding: 5px 25px;
                    h4 {
                        line-height: 5px;
                        font-size: 2.2vw;
                    }
                    h5 {
                        font-size: 1.5vw;
                    }
                }
                .bottom-text {
                    h5 {
                        font-size: 1.5vw;
                    }
                }
            }
        }
	}
	.burst-box01 {
		.section-text {
            .interior {
                background-image: url('../images/burst_chill_bg.jpg');
                //background: rgba(51, 91, 160, 0.973);
            }
		}
	}
	.burst-box02 {
		.section-text {
			.interior {
                background-image: url('../images/__');
                background: rgba(51, 91, 160, 0.973);
            }
		}
	}
	.burst-box03 {
		.section-text {
			.interior {background-image: url('../images/burst_inventory_bg.jpg');}
		}
	}
	.burst-box04 {
		.section-text {
			.interior {
                background-image: url('../images/burst_img_nb_04.jpg');
                background: rgba(51, 91, 160, 0.973);
            }
		}
	}
	@media screen and (max-width: 991px){
		padding: 30px 10px;
		.burst-box01, .burst-box02, .burst-box03, .burst-box04 {
			height: calc(100vw *  200 / 1550);
		}
	}
	@media screen and (max-width: 768px){
		h3 {
            font-size: 30px;
        }
		.burst-box01, .burst-box02, .burst-box03, .burst-box04 {
			height: auto;
			flex-direction: column;
			.img-left {width: 100%;}
			.center {display: none;}
			.section-text {
				h4 {
					//font-size: 20px;
					text-align: center;
					width: 100%;
				}
				p {
					text-align: center;
					width: 100%;
				}
				width: 100%;
				height: 150px;

			}
        }
        div.section-text {
            div.top-text {
                padding: 10px 25px;
                h5 {
                    top: 5px!important;
                    position: relative!important;
                    font-size: 18px!important;
                    text-align: center;
                }
                h4 {
                    line-height: 20px!important;
                    font-size: 24px!important;
                    text-align: center;
                }
            }

            div.bottom-text {
                h5 {
                    width: 90%;
                    line-height: 20px!important;
                    font-size: 24px!important;
                    text-align: center;
                }
            }
        }
	}
}

//////////////// HEADER - STYLE GENERAL
section.section-header.index {
    div.module-header {
        div.content-title {
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 9;
            height: 49.5vw;
            display: flex;
            position: absolute;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: $size-max-3) {
                height: 52.5vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    height: 134.5vw;
                    transition: .5s;
                }
            }

            h3 {
                width: 32vw;
                color: #fff;
                font-weight: 400;
                font-size: 42px;
                color: $color-white;
                border: 3px solid $color-white;     
                text-shadow: 1px 4px 4px $color-black;           
                text-align: center;
                min-width: 300px;
                padding: 30px;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    padding: 25px;
                    font-size: 35px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        padding: 18px;
                        font-size: 30px;
                        transition: .5s;
                    }
                }
            }
        }
    }
}

////////////// PAGE - Industrie Laitier
section.section-02-main-industrie {
    background: url(../images/background_main.jpg) no-repeat center center;
    background-size: cover;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 120px 0;

    div.container-fluid.row {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        padding-left: 0;
        @media screen and (max-width: $size-max-m-2) {
            padding-left: 15px;
            flex-direction: column;
            transition: .5s;
        }

        div.left-side.industrie-laitier,
        div.right-side.industrie-laitier {
            width: 50%;
            @media screen and (max-width: $size-max-m-2) {
                width: 100%;
                transition: .5s;
            }
        }

        div.left-side.industrie-laitier {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            //min-width: 650px;
            div.red-box {
                width: 85%;
                height: 195px;
                display: flex;
                justify-content: flex-end;
                background: $color-red-1;
                align-items: center;
                padding-right: 5vw;
                min-width: 650px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    width: 95%;
                    min-width: auto;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        height: 150px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            width: 100%;
                            padding: 0;
                            justify-content: center;
                            transition: .5s;
                        }
                    }
                }

                h3.text-in-box {
                    color: $color-white;
                    font-size: 40px;
                    font-weight: 400;
                    line-height: 45px;
                    text-transform: none;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 35px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 25px;
                            line-height: 35px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 18px;
                                line-height: 28px;
                                transition: .5s;
                            }
                            @media screen and (max-width: $size-max-m-2) {
                                text-align: center;
                                transition: .5s;
                            }
                        }
                    }

                }
            }
            div.content-img {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 65px;
                min-width: 650px;
                @media screen and (max-width: $size-max-3) {
                    min-width: auto;
                    transition: .5s;
                }
                img {
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        width: 70%;
                        transition: .5s;
                    }
                }
            }
        }

        div.right-side.industrie-laitier {
            @media screen and (max-width: $size-max-m-2) {
                margin-top: 65px;
                width: 100%;
                padding: 0;
                justify-content: center;
                transition: .5s;
            }
            div.content-logo {
                width: 75%;
                min-width: 650px;
                border-top: 4px solid $color-blue;
                border-bottom: 4px solid $color-blue;
                margin-bottom: 65px;
                padding: 40px 25px;
                @media screen and (max-width: $size-max-3) {
                    width: 100%;
                    min-width: auto;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        height: 150px;
                        padding: 12px 25px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            display: flex;
                            justify-content: center;
                            align-items: center;                            
                            transition: .5s;
                        }
                    }
                }
            }

            div.content-paragraphs {
                width: 75%;
                min-width: 650px;
                padding-bottom: 45px;
                border-bottom: 4px solid $color-blue;
                @media screen and (max-width: $size-max-3) {
                    width: 100%;
                    min-width: auto;
                    transition: .5s;
                }

                div.content-inner-img-text {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        justify-content: center;
                        flex-direction: column;
                        transition: .5s;
                    }

                    img {
                        margin-right: 45px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            margin: 0;
                            transition: .5s;
                        }
                    }

                    div.btn-border-general {
                        padding: 7px;
                        background: $color-blue-2;
                        width: 420px;
                        height: 75px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            width: 320px;
                        }
                        
                        a {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: $color-white;
                            border: 1px solid $color-white;
                            font-size: 18px;
                            line-height: 25px;
                            text-align: center;
                            transition: .5s;
                        }
                    }
                    div.btn-border-general:hover {
                        background: $color-blue-2-hover;
                        transition: .5s;
                    }
                }

                p {
                    color: $color-blue;
                    font-weight: 400;
                    font-size: 25px;
                    text-align: left;
                    margin-bottom: 25px;
                    line-height: 40px;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 20px;
                        line-height: 32px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 17px;
                            line-height: 28px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                text-align: center;                        
                                transition: .5s;
                            }
                        }
                    }                    
                    img {
                        margin-top: 35px;
                        @media screen and (max-width: $size-max-m-2) {
                            margin: 0 auto;
                            margin-top: 30px;                       
                            transition: .5s;
                        }
                    }
                }
            }
            div.content-paragraphs.transformation-page {
                border-top: 4px solid $color-blue;
                padding-top: 45px;

                ol.list-equiments,
                ul.list-equiments {
                    //list-style: none;
                    //list-style-type: none;
                    @media screen and (max-width: $size-max-m-1) {
                        list-style: none;
                        list-style-type: none;
                        padding: 0;
                        transition: .5s;
                    }
                    li.list-item {
                        text-transform: none;
                        color: $color-blue-0;
                        font-size: 25px;
                        font-weight: 600;
                        line-height: 45px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 22px;
                            line-height: 45px;
                            transition: .5s;
                        }
                        @media screen and (max-width: $size-max-3) {
                            font-size: 20px;
                            line-height: 45px;
                            transition: .5s;
                        }
                        @media screen and (max-width: $size-max-m-1) {
                            font-size: 18px;
                            line-height: 45px;
                            transition: .5s;
                        }
                        @media screen and (max-width: $size-max-m-1) {
                            text-align: center;
                            transition: .5s;
                        }
                    }
                }
            }
        }

        div.right-side.industrie-laitier.emplois {
            div.content-paragraphs {
                border-top: 4px solid #004b8e;
                padding-top: 45px;

                div.content-inner-img-text {
                    div.btn-border-general {
                        @media screen and (max-width: $size-max-m-3) {
                            width: 285px;
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }    
}

.blue-uper {
    font-weight: 600;
    text-transform: uppercase;
}

section.section-02-main-industrie.page-usages {
    background: url(../images/background_main.jpg) no-repeat center center;
    background-size: cover;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0 0;

    div.container-fluid.row {
        padding: 0;
        display: flex;

        div.same-height {
            flex: 1;
            display: flex;
            flex-direction: column;
            //border: 1px solid;
            // padding-bottom: 100%;
            // margin-bottom: -100%;
        }

        div.left-side.industrie-laitier {
            @media screen and (max-width: $size-max-m-2) {
                justify-content: center;
                align-items: center;
                transition: .5s;
            }
            div.red-box {
                width: 45%;
                position: absolute;
                margin-top: 7.666vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    width: 95%;
                    transition: .5s;
                }
            }
            div.content-img-bg {
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
        div.right-side.industrie-laitier {
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                margin-bottom: 65px;
                transition: .5s;
            }
            div.content-border {
                min-height: 48vw;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    padding: 0 65px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        padding: 0 35px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            padding: 0 15px;
                            transition: .5s;
                        }
                    }
                }
            }
            div.content-paragraphs {
                border-top: 4px solid $color-blue;
                border-bottom: 4px solid $color-blue;
                padding-top: 65px;
                min-width: 600px;
                width: 65%;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    width: 65%;
                    min-width: 100%;
                    margin: 0 55px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        padding: 0;
                        padding-top: 25px;
                        transition: .5s;
                    }
                }

                p {
                    width: 60%;
                    max-width: 350px;
                    font-size: 27px;
                    transition: .5s;
                    @media screen and (max-width: 1795px) {
                        width: 65%;
                        transition: .5s;
                    }
                    @media screen and (max-width: 1675px) {
                        width: 75%;
                        max-width: 100%;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 22px;
                            line-height: 35px;
                            transition: .5s;
                        }
                        @media screen and (max-width: $size-max-4) {
                            width: 100%;
                            font-size: 20px;
                            line-height: 30px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 18px;
                                line-height: 25px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }
    }
}


section.section-02-main-industrie.page-usages.installation {
    div.content-img-bg {
        width: 100%;
        img {
            width: 100%;
        }
    }

    div.left-side.industrie-laitier.same-height {
        div.red-box {
            padding: 0;
            h3.text-in-box {
                width: 100%;
                text-align: center;
                @media screen and (max-width: $size-max-m-2) {
                    font-size: 20px;
                    transition: .5s;
                }
            }
        }
    }

    div.right-side.industrie-laitier.same-height {
        div.content-border {
            @media screen and (max-width: 848px) {
                min-height: 49.555vw;
                transition: .5s;
            }
            div.content-paragraphs {
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    transition: .5s;
                }
                p {
                    width: 100%;
                    max-width: 100%;
                    @media screen and (max-width: 848px) {
                        margin-bottom: 14px;
                        transition: .5s;
                    }
                }
            }
            ul.list-equiments {
                @media screen and (max-width: $size-max-m-2) {
                    padding: 0;
                    // list-style: none;
                    // list-style-type: none;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    transition: .5s;
                    text-align: left;
                    width: 200px;
                }
                li.list-item {
                    color: $color-blue;
                    font-size: 22px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 17px;
                        line-height: 30px;
                        transition: .5s;
                    }
                    @media screen and (max-width: 848px) {
                        font-size: 15px;
                        line-height: 25px;
                        transition: .5s;
                    }
                    @media screen and (max-width: $size-max-m-2) {
                        width: 200px;
                        line-height: 32px;
                        text-align: center;
                        transition: .5s;
                    }
                }
            }
        }

        .blue-uper {
            font-size: 35px;
        }
    }
}
////////////// PAGE - Industrie Laitier
section.section-03-last-one {
    padding: 35px;
    background: url(../images/parallax_strip.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: .5s;
    @media screen and (max-width: $size-max-m-3) {
        padding: 15px;
        transition: .5s;
    }

    div.container {
        justify-content: center;
        align-items: center;
        display: flex;
        @media screen and (max-width: $size-max-m-2) {
            width: 100%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-3) {
                padding: 0;
                transition: .5s;
            }
        }
        a.box-content {
            border: 3px solid #FFF;
            justify-content: center;
            align-items: center;
            display: flex;
            height: 220px;
            padding: 0;
            width: 790px;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                //height: 180px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    width: 100%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-3) {
                        display: flex;
                        flex-direction: column;
                        height: auto;
                        transition: .5s;
                    }
                }
            }

            div.left-last-img {
                width: 35%;
                height: 100%;
                border-right: 3px solid #FFF;
                justify-content: center;
                align-items: center;
                display: flex;
                @media screen and (max-width: $size-max-m-3) {
                    width: 24%;
                    padding-right: 15px;
                    transition: .5s;
                }
                @media screen and (max-width: $size-max-m-3) {
                    width: 100%;
                    border-right: 0 solid #FFF;
                    border-bottom: 3px solid #FFF;
                    padding: 15px;
                    transition: .5s;
                }
                img {
                    // @media screen and (max-width: $size-max-m-2) {
                    //     width: 95%;
                    //     transition: .5s;
                    // }
                }
            }
            div.right-last-text {
                width: 65%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 0 15px;
                @media screen and (max-width: $size-max-m-3) {
                    width: 100%;
                    transition: .5s;
                }
                h3 {
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                    text-transform: none;
                    color: $color-white;

                    .text-nomal {
                        font-family: $font-family-1;
                        line-height: 40px;
                        font-weight: 500;
                        font-size: 25px;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 20px;
                            line-height: 30px;
                            transition: .5s;
                        }
                        @media screen and (max-width: $size-max-m-3) {
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            transition: .5s;
                            width: 100%;
                        }
                    }

                    .text-uper {
                        font-weight: 200;
                        font-size: 40px;
                        text-transform: uppercase;
                        line-height: 40px;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 20px;
                            line-height: 30px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-3) {
                                justify-content: center;
                                align-items: center;
                                text-align: center;
                                transition: .5s;
                                width: 100%;
                            }
                        }
                    }
                }

                .uper-soft {
                    font-weight: 200;
                    font-size: 40px;
                    text-transform: uppercase;
                    line-height: 40px;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 20px;
                        line-height: 30px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-3) {
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            transition: .5s;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

//////////////// SECTION PRODUCT - List
section.section-04-product.index {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 75px 0;
    padding-top: 0;

    div.container-fluid.row {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        @media screen and (max-width: $size-max-m-3) {
            flex-direction: column;
        }

        div.col-md-3.unique-product {
            display: flex;
            flex-direction: column;
            width: 24.999%;
            min-width: 300px;
            min-height: 300px;
            @media screen and (max-width: $size-max-m-1) {
                width: 50%;
            }
            @media screen and (max-width: $size-max-m-3) {
                width: 100%;
                margin-bottom: 45px;
            }

            div.content-photo {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 300px;
                min-height: 300px;             
                a {
                    img {
                        //width: 100%;
                    }
                }
            }

            div.content-descriptions {
                padding: 15px 0;

                h3.title-product {
                    text-transform: none;
                    color: $color-blue;
                    font-weight: 500;
                    font-size: 22px;
                    text-align: left;
                    height: 60px;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 20px;
                        text-align: center;
                        transition: .5s;
                    }
                    @media screen and (max-width: $size-max-m-3) {
                        font-size: 18px;
                        text-align: center;
                        transition: .5s;
                    }
                }

                div.content-descriptiton {
                    margin-top: 5px;
                    height: 60px;
                    max-height: 60px;
                    overflow: hidden;

                    div {
                        color: $color-black;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 25px;
                        @media screen and (max-width: $size-max-m-2) {
                            font-size: 16px;
                            transition: .5s;
                        }
                        @media screen and (max-width: $size-max-m-3) {
                            text-align: center;
                            transition: .5s;
                        }
                    }
                    p {
                        color: $color-black;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 25px;
                        @media screen and (max-width: $size-max-m-2) {
                            font-size: 16px;
                            transition: .5s;
                        }
                        @media screen and (max-width: $size-max-m-3) {
                            text-align: center;
                            transition: .5s;
                        }
                    }
                }

                div.content-btn {
                    width: 100%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $color-blue-2;
                    margin-top: 15px;
                    padding: 5px;
                    transition: .5s;

                    a.btn-voir-plus {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        border: 1px solid $color-white;
                        align-items: center;
                        justify-content: center;
                        color: $color-white;
                        font-size: 16px;
                        font-weight: 500;
                        font-family: $font-family-1;
                        @media screen and (max-width: $size-max-m-2) {
                            font-size: 14px;
                            transition: .5s;
                        }
                    }
                }
                div.content-btn:hover {
                    background: $color-blue-2-hover;
                    transition: .5s;
                }
            }
        }
    }
}

///////////// PAGINATION STYLE - GENERAL
.pagination {
    width: 100%;
    //flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 35px 0;
    display: flex;

    div.row {
        width: 100%;

        div.row.tools {
            margin: 0;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                display: flex;
                justify-content: center;
                transition: .5s;
            }
        }

        div.items {
            width: 90px;
            margin: 0 10px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-3) {
                width: 52px;
                transition: .5s;
            }

            div {
                font-size: 18px;
                color: $color-blue;
            }
        }
    }

    div.list-pagination {
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    span.title-bottom {
        padding: 10px 0;
        color: $color-black;
        font-family: $font-family-1;
        font-weight: 400;
        font-size: 16px;
        width: 100%;
    }
}

div.pagination.product-used {
    width: 375px;
    transition: .5s; 
    div.pagination {
        width: 375px;
        @media screen and (max-width: $size-max-m-4) {
            width: 100%;
            margin-left: -5px;
            transition: .5s;
        }
    }
}

div.content-filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    margin-bottom: 15px;
    align-items: center;
    padding: 0 15px;
    @media screen and (max-width: $size-max-m-2) {
        flex-direction: column;
        height: auto;
    }

    div.content-select-cat {
        @media screen and (max-width: $size-max-m-2) {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 45px;
        }
    }

    .pagination {
        @media screen and (max-width: $size-max-m-2) {
            width: 100%;
            justify-content: center;
            align-items: center;
            .title-bottom {
                text-align: center;
            }
        }
    }

    .pagination a {
        color: black;
        float: left;
        padding: 10px 17px;
        text-decoration: none;
        transition: background-color .3s;
        border: 1px solid #ddd;
        margin: 0 4px;
        @media screen and (max-width: $size-max-m-2) {
            width: 100%;
            justify-content: center;
            align-items: center;
        }
    }
      
    .pagination a.active {
        background-color: $color-red-1;
        color: white;
        border: 1px solid $color-red-1;
    }

    div.dropdown-select-cathery {
        flex-direction: column;
        position: relative;
        display: flex;
        width: 350px;
        span {
            padding: 16px 18px;
            background: $color-white;
            border: 1px solid $color-blue;
            font-size: 19px;
            border-radius: 2px;
            color: $color-blue;
            cursor: pointer;
            width: 100%;

            background-image:
                linear-gradient(45deg, transparent 50%, $color-blue 50%),
                linear-gradient(135deg, $color-blue 50%, transparent 50%),
                radial-gradient(#ddd 70%, transparent 72%);
            background-position:
                calc(100% - 20px) calc(1em + 2px),
                calc(100% - 15px) calc(1em + 2px),
                calc(100% - .3em) .5em;
            background-size:
                5px 5px,
                5px 5px,
                1.5em 1.5em;
            background-repeat: no-repeat;
        }

        span:focus {
            background-image:
              linear-gradient(45deg, white 50%, transparent 50%),
              linear-gradient(135deg, transparent 50%, white 50%),
              radial-gradient(gray 70%, transparent 72%);
            background-position:
              calc(100% - 15px) 1em,
              calc(100% - 20px) 1em,
              calc(100% - .5em) .5em;
            background-size:
              5px 5px,
              5px 5px,
              1.5em 1.5em;
            background-repeat: no-repeat;
            border-color: green;
            outline: 0;
        }

        label.selected-cathegorie {
            text-align: center;
            font-size: 18px;
            color: $color-blue-2;
            font-weight: 600;
            margin-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid $color-blue;
        }

        div.select-cathery-content {
            display: none;
            //display: flex;
            position: absolute;
            flex-direction: column;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            padding: 12px 16px;
            margin-top: 53px;
            width: 100%;
            z-index: 1;            
            a {
                padding: 7px 0;
                font-size: 17px;
                font-weight: 400;
                line-height: 25px;
                color: $color-blue;
                border-bottom: 1px solid #ccc;
                transition: .5s;
            }
            a:hover {
                color: $color-red;
                transition: .5s;
            }
        }
    }
    div.dropdown-select-cathery:hover {
        div.select-cathery-content {
            display: flex;
        }
    }
}

.title-empty {
    width: 100%;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    color: #ccc;
    transition: .5s;
    @media screen and (max-width: $size-max-2) {
        font-size: 30px;
        transition: .5s;
    }
    @media screen and (max-width: $size-max-3) {
        font-size: 25px;
        transition: .5s;
    }
    @media screen and (max-width: $size-max-m-1) {
        font-size: 20px;
        transition: .5s;
    }
}

section.section-04-product.emplois {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 95px 0;
    transition: .5s;

    div.container-flui-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85%;
        @media screen and (max-width: $size-max-2) {
            width: 100%;
            transition: .5s;
        }
        @media screen and (max-width: $size-max-3) {
            width: 95%;
            transition: .5s;
        }
        @media screen and (max-width: $size-max-4) {
            width: 100%;
            transition: .5s;
        }
    }
    div.container-flui.row {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        @media screen and (max-width: $size-max-m-1) {
            padding: 0 15px;
            flex-direction: column;
            transition: .5s;
        }
        
        div.content-content-offert {
            display: flex;
            justify-content: space-around;
            justify-items: flex-start;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: .5s;
            }

            div.content-photo {
                width: 35%;
                min-width: 300px;
                @media screen and (max-width: $size-max-2) {
                    min-width: 250px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        min-width: 200px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            min-width: 150px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                min-width: 300px;
                                margin-bottom: 25px;
                                transition: .5s;
                            }
                        }
                    }
                }
                img {
                    width: 100%;
                    border-radius: 2px;
                }
            }

            div.content-description {
                width: 65%;
                padding-left: 15px;
                @media screen and (max-width: $size-max-m-2) {
                    width: 100%;
                    padding: 0;
                    transition: .5s;
                }
            }
        }

        div.box-unique-offert {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 95px;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                width: 50%;
                transition: .5s;
            }
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                transition: .5s;
            }
            div.content-offert {
                padding: 15px;
                border-top: 1px solid #CCC;
                transition: .5s;
                width: 90%;
                @media screen and (max-width: $size-max-4) {
                    width: 100%;
                    transition: .5s;
                }
                @media screen and (max-width: $size-max-m-1) {
                    width: 100%;
                    transition: .5s;
                }

                h3.title-offert {
                    color: $color-blue;
                    font-size: 25px;
                    font-weight: 500;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 20px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 18px;
                            transition: .5s;
                        }
                    }
                    @media screen and (max-width: $size-max-m-2) {
                        text-align: center;
                        transition: .5s;
                    }
                }

                h4.date-published {
                    display: flex;
                    justify-content: flex-start;
                    text-transform: uppercase;
                    align-items: center;
                    margin-bottom: 20px;
                    margin-top: 10px;
                    @media screen and (max-width: $size-max-3) {
                        margin-bottom: 10px;
                        margin-top: 8px;
                        transition: .5s;
                    }
                    @media screen and (max-width: $size-max-m-2) {
                        justify-content: center;
                        transition: .5s;
                    }

                    span.label {
                        color: $color-black;
                        text-transform: uppercase;
                        font-weight: 400;
                        font-size: 17px;
                    }

                    span.date {
                        text-transform: uppercase;
                        color: $color-red-1;
                        margin-left: 10px;
                        font-weight: 600;
                        font-size: 17px;
                    }
                }

                div.content-body-offert {
                    //height: 120px;
                    overflow: hidden;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        height: auto;
                        transition: .5s;
                    }
                    h3.succursal {
                        color: $color-blue;
                        font-size: 20px;
                        font-weight: 400;
                        margin-bottom: 15px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            margin-bottom: 10px;
                            transition: .5s;
                        }
                        @media screen and (max-width: $size-max-m-2) {
                            text-align: center;
                            transition: .5s;
                        }
                    }
                    p {
                        font-size: 19px;
                        font-weight: 400;
                        color: $color-black;
                        line-height: 28px;
                        min-width: 320px;
                        width: 100%;
                        @media screen and (max-width: $size-max-3) {
                            line-height: 20px;
                            font-size: 16px;
                            transition: .5s;
                        }
                        @media screen and (max-width: $size-max-m-1) {
                            // width: 100%;
                            font-size: 16px;
                            transition: .5s;
                        }
                        @media screen and (max-width: $size-max-m-2) {
                            text-align: center;
                            padding: 0 10px;
                            transition: .5s;
                        }                        
                    }
                }

                #see_more,
                #see_less {
                    padding: 2px 15px;
                    cursor: pointer;
                    font-size: 18px;
                    padding-left: 0;
                    transition: .5s;
                }
                #see_more:hover,
                #see_less:hover {
                    color: $color-blue;
                    transition: .5s;
                }

                a.link-PDF {
                    width: 100%;
                    color: $color-blue;
                    font-size: 20px;
                    font-weight: 600;
                    margin: 15px 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    @media screen and (max-width: $size-max-3) {
                        width: 80%;
                        font-size: 17px;
                        transition: .5s;
                    }
                    @media screen and (max-width: $size-max-m-2) {
                        justify-content: center;
                        text-align: center;
                        transition: .5s;
                        width: 100%;
                    }
                }

                div.footer-offert {
                    border-top: 1px solid #ccc;
                    padding-top: 15px;
                    margin-top: 15px;
                    @media screen and (max-width: $size-max-m-2) {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        transition: .5s;
                    }

                    div.btn-inner-red {
                        width: 300px;
                        height: 70px;
                        padding: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: $color-red-1;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            width: 215px;
                            height: 60px;
                            transition: .5s;
                        }

                        a {
                            border: 1px solid $color-white;
                            color: $color-white;
                            font-size: 20px;
                            text-align: center;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-transform: uppercase;
                            font-weight: 400;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 17px;
                                transition: .5s;
                            }
                        }
                    }

                    div.btn-inner-red:hover {
                        background: $color-red-1-hover;
                        transition: .5s;
                    }
                }
            }
        }
    }
}

////////// SECTION NOS VALEURS
section.section-nos-valeurs {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 65px 15px;
    background: url(../images/emploi_section_02_bg_parallax.jpg) no-repeat center center;
    background-size: cover;
    div.left-side-valeurs,
    div.right-side-valeurs {
        width: 50%;
        transition: .5s;
    }

    div.left-side-valeurs {
        @media screen and (max-width: $size-max-m-1) {
            display: none;
        }
    }
    div.right-side-valeurs {
        @media screen and (max-width: $size-max-m-1) {
            width: 80%;
            transition: .5s;
            padding: 15px;
            @media screen and (max-width: $size-max-m-2) {
                width: 100%;
                transition: .5s;
            }
        }
    }

    h3.title-list {
        color: $color-white;
        font-size: 30px;
        margin-bottom: 25px;
        margin-left: -20px;
        @media screen and (max-width: $size-max-m-1) {
            font-size: 25px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                font-size: 20px;
                transition: .5s;
            }
        }
    }

    ul.nos-valeur-list {
        li.item {
            color: $color-white;
            text-transform: none;
            line-height: 34px;
            font-size: 18px;
            font-weight: 500;
        }
    }
}


//////////// PAGE SOUMMISSION
section.section-02-soumission {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-red-1;
    margin-bottom: 45px;
    padding: 68px 0;

    div.content-paragraph {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 45%;
        min-width: 650px;
        transition: .5s;
        @media screen and (max-width: $size-max-m-2) {
            min-width: 100%;
            padding: 10px;
            transition: .5s;
        }

        p {
            text-align: center;
            color: $color-white;
            font-weight: 400;
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 45px;
            @media screen and (max-width: $size-max-2) {
                font-size: 25px;
                line-height: 35px;
            }
            @media screen and (max-width: $size-max-3) {
                font-size: 20px;
                line-height: 32px;
                @media screen and (max-width: $size-max-m-2) {
                    font-size: 18px;
                    line-height: 32px;
                }
            }
        }
    }
}


///////////// Section - 02 - coordonnées
section.section-02-coordonnes {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 95px 0;
    padding-bottom: 0;
    
    div.container-fluid.row {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 0;
        width: 100%;

        div.top-informations {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding-bottom: 65px;

            div.address-content {
                width: 50%;
                min-width: 650px;
                border-top: 3px solid $color-blue;
                border-bottom: 3px solid $color-blue;
                justify-content: center;
                align-items: center;
                padding: 35px 10px;
                margin-bottom: 35px;
                display: flex;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    padding: 25px 10px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        width: 95%;
                        min-width: 95%;
                        transition: .5s;
                    }
                }

                h3 {
                    color: $color-blue;
                    font-size: 42px;
                    font-weight: 300;
                    line-height: 50px;
                    text-align: center;
                    text-transform: none;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 35px;
                        line-height: 40px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 30px;
                            line-height: 35px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 25px;
                                line-height: 35px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-3) {
                                    font-size: 22px;
                                    line-height: 35px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }

            div.other-infos {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 25px;
                @media screen and (max-width: $size-max-m-3) {
                    flex-direction: column;
                    transition: .5s;
                }

                h3 {
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        width: 100%;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 15px;
                        transition: .5s;
                    }
                    span.label {
                        text-transform: none;
                        color: $color-blue;
                        font-weight: 500;
                        font-size: 25px;
                        transition: .5s;
                    }

                    a {
                        text-transform: none;
                        color: $color-red-1;
                        font-size: 50px;
                        font-weight: 400;
                        transition: .5s;
                    }
                    @media screen and (max-width: $size-max-2) {
                        span.label {
                            font-size: 22px;
                            transition: .5s;
                        }
                        a {
                            font-size: 40px;
                            transition: .5s;
                        }
                        @media screen and (max-width: $size-max-3) {
                            span.label {
                                font-size: 22px;
                                transition: .5s;
                            }
                            a {
                                font-size: 40px;
                                transition: .5s;
                            }
                            @media screen and (max-width: $size-max-m-1) {
                                span.label {
                                    font-size: 17px;
                                    transition: .5s;
                                }
                                a {
                                    font-size: 25px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
                .separator {
                    text-transform: none;
                    color: $color-blue;
                    margin: 0 17px;
                    font-size: 34px;
                    font-weight: 700;
                    margin-top: 14px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 30px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 25px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 20px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-3) {
                                    display: none;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }

            h3.email {
                a {
                    text-transform: none;
                    color: $color-blue;
                    font-weight: 500;
                    font-size: 25px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 23px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 20px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 18px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }

        div.bottom-max {
            width: 100%;
            height: 750px;
        }
    }
}
//////// Coordonnées version - 02
section.section-map-emails {
    display: flex;
    justify-content: center;
    align-items: center;
    div.container-fluid.row {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $blue-bg;
        padding: 0;
        width: 100%;
        @media screen and (max-width: $size-max-m-1) {
            flex-direction: column;
            transition: .5s;
        }

        div.bottom-max,
        div.list-emails {
            width: 50%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                transition: .5s;
            }
        }
        div.list-emails {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 15px 5vw;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                padding: 15px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    padding: 7vw 5vw;
                    transition: .5s;
                }
            }

            div.emplouyer {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                border-bottom: 1px solid $color-white;
                margin-bottom: 15px;
                padding-bottom: 15px;
                @media screen and (max-width: $size-max-m-2) {
                    justify-content: center;
                    align-items: center;
                    transition: .5s;
                }

                h3.name-employer {
                    color: $color-white;
                    margin-bottom: 5px;
                    font-weight: 500;
                    font-size: 18px;
                }

                div {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    transition: .5s;
                    width: 100%;
                    @media screen and (max-width: $size-max-m-2) {
                        flex-direction: column;
                        transition: .5s;
                    }

                    a,
                    label {
                        color: $color-white;
                        font-weight: 400;
                        width: 50%;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            width: 100%;
                            text-align: center;
                            transition: .5s;
                        }
                    }

                    label {
                        width: 70%;
                        @media screen and (max-width: $size-max-m-2) {
                            width: 100%;
                            text-align: center;
                            transition: .5s;
                        }
                    }

                    a {
                        //text-align: right;
                        transition: .5s; 
                        &:hover {
                            color: $color-red;
                            transition: .5s; 
                        }
                    }
                }
            }
        }
    }
}

/////// Checkbox style
div.content-checkbox-blox {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 5px;
    div.checkbox-blox {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 20px;
        /* The container */
        .container {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 20px;
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            color: $color-black;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            line-height: 20px;
            transition: .5s;
    
            span {
                margin-top: -4px;
                transition: .5s;
            }
        }
    
        /* Hide the browser's default checkbox */
        .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            transition: .5s;
        }
    
        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #fff;
            border: 2px solid $color-blue;
            border-radius: 3px;
            transition: .5s;
        }
    
        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
            background-color: #ccc;
            border: 2px solid $color-blue;
            border-radius: 3px;
            transition: .7s;
        }
    
        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
            background-color: $color-white;
            border: 1px solid $color-blue;
            transition: .5s;
        }
    
        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            //display: none;
            opacity: 0;
            transition: .5s;
        }
    
        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
            //display: block;
            opacity: 1;
            transition: .5s;
        }
    
        /* Style the checkmark/indicator */
        .container .checkmark:after {
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border: solid $color-white;
            background: $color-blue;
            //border-width: 8px 6px 5px 7px;
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
            transition: .5s;
        }
    }
}

.pagination a:hover:not(.active) {background-color: #ddd;}
///////////// END ./ PAGINATION STYLE - GENERAL

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
  content:"";
  display:block;
  height:$size-nav-height; /* fixed header height*/
  margin:-$size-nav-height 0 0; /* negative fixed header height */
}

.shadow {
	position: relative;
	&::after {
		content: '';
		z-index: 1;
		width: 100%;
		top: 100%;
		height: 20px;
		position: absolute;
		background: linear-gradient(to bottom , rgba(26, 26, 26, 0.50), rgba(0 ,0 ,0 ,0) );
	}
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/soumission';

/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/


/*******************************************************************************
* X PAGE
*/

.pageX {
    section.header {
        transition: all .2s linear;
        &:hover {
            transition: all .2s linear;
        }
    }
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: $size-md-max){
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-sm-max){
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-xs-max){
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {
        h2 {
            font-size: 3em;
        }
    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}
