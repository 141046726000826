.ls-bottom-nav-wrapper {
    top: -15px;
}

.slideshow-wrap {
    position: relative;
    top: 0;
    position: relative;
    margin-top: -28px;
    #layerslider { display: block; }
    #layerslider-mobile { display: none; }
    @media screen and (max-width: $size-slider-breakpoint) {
        #layerslider { display: none; }
        #layerslider-mobile { display: block; }
    }

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        font-size: 1.5vw;
        text-align: center;
        padding-bottom:0.5em;
    }
    .title {
        @extend .font-2;
        text-transform: uppercase;
        color: $color-primary;
        font-size: 3vw;
        text-align: center;
    }
    .ls-l a {
        width: auto;
    }
    
    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: 11px;
            border-width: 1px;
            padding: 5px;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        .content-logo-ss {
            display: none;
        }
        .title {
            font-family: arial;
            font-size: 22px;
            color: $color-white;
            line-height: 25px;
            font-weight: bolder;
        }
        .subtitle {
            font-size: 16px;
        }
    }
}

div.subtitle {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    position: absolute;
    align-items: center;
    height: 45vw;
    width: 100%;
    div.row {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        div.left-side {
            width: 80%;
        }
        div.right-side {
            width: 35%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            div.col-xs-12 {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 35px;
                width: 510px;
                img {
                    width: 225px;
                    display: none;
                }
            }
            div.box-text-container {
                width: 650px;
                padding: 15px;
                background: rgba(255, 255, 255, 0.142);
                div.content-text {
                    padding: 25px;
                    padding-left: 45px;
                    //border: 2px solid #FFF;
                    h3 {
                        color: #0048b7;
                        text-transform: none;
                        text-decoration: none;
                        text-align: left;
                        font-size: 40px;
                        font-weight: 500;
                        margin-top: 25px;
                        margin-bottom: 40px;
                        line-height: 48px;
                        letter-spacing: 2px;
                        text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.62);
                    }
                }                
            }
        }
    }
}

.ls-bottom-nav-wrapper {
    //display: none;
}

@media screen and (max-width: 1555px) {
    div.subtitle {
        div.row {
            div.right-side {
                top: 2vw;
                position: relative;
                div.col-xs-12 {
                    width: 410px;
                    img {
                        width: 225px;
                    }
                }
                div.box-text-container {
                    width: 380px;
                    padding: 10px;
                    div.content-text {
                        padding: 20px;
                        padding-left: 25px;
                        h3 {
                            font-size: 32px;
                            margin-top: 25px;
                            margin-bottom: 25px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .ls-bottom-nav-wrapper {
        margin-top: 20px; 
    }
    
    div.subtitle {
        div.row {
            width: 100%;
            div.right-side {
                top: 2vw;
                position: relative;
                div.col-xs-12 {                    
                    margin-bottom: 20px;
                    width: 300px;
                    img {
                        width: 185px;
                    }
                }
                div.box-text-container {
                    width: 320px;
                    div.content-text {
                        padding: 0px;
                        padding-left: 0;
                        padding-right: 20px;
                        h3 {
                            font-size: 24px;
                            margin-top: 22px;
                            margin-bottom: 35px;
                            line-height: 30px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .ls-bottom-nav-wrapper {
        margin-top: 50px; 
    }
}

@media screen and (max-width: 768px) {
    div.subtitle {
        div.row {
            //width: 4%;
            div.right-side {
                top: 2vw;
                position: relative;
                div.col-xs-12 {                    
                    margin-bottom: 5px;
                    width: 300px;
                    display: none;
                    img {
                        width: 185px;
                    }
                }
                div.box-text-container {
                    width: 240px;
                    margin-right: 45px;
                    background: rgba(255, 255, 255, 0.53);
                    border-radius: 3px;
                    div.content-text {
                        padding: 0px;
                        padding-left: 0;
                        h3 {
                            font-size: 20px;
                            margin-top: 10px;
                            margin-bottom: 12px;
                            line-height: 23px;
                            font-weight: 400;
                            text-align: center;
                            /*
                            font-size: 22px;
                            margin-top: 22px;
                            margin-bottom: 20px;
                            line-height: 25px;
                            */
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    div.subtitle {
        div.row {
            width: 4%;
            div.right-side {
                top: 2vw;
                position: relative;
                div.col-xs-12 {                    
                    margin-bottom: 5px;
                    width: 300px;
                    display: none;
                    img {
                        width: 185px;
                    }
                }
                div.box-text-container {
                    right: 0;
                    width: 290px;                    
                    position: relative;
                    margin-right: 0;
                    div.content-text {
                        padding: 0px;
                        padding-left: 0;
                        h3 {
                            font-size: 20px;
                            margin-top: 10px;
                            margin-bottom: 12px;
                            line-height: 23px;
                            font-weight: 400;
                            text-align: center;
                            /*
                            font-size: 22px;
                            margin-top: 22px;
                            margin-bottom: 20px;
                            line-height: 25px;
                            */
                        }
                    }
                }
            }
        }
    }
}